import _AIAssistantServiceAction from "cms/actions/services/AIAssistantServiceAction";


const screenCode = "214";
class AIAssistantServiceAction extends _AIAssistantServiceAction {

    constructor( dispatch: any ) {
        super(dispatch, screenCode);
    }
    
}

export default AIAssistantServiceAction;
