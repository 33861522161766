import { combineReducers } from 'redux';
// 
import AIAssistantServiceStore from "cms/reducers/services/AIAssistantServiceStore";


const screenCode = "214";

const RMDRAIAssistantStore = combineReducers({
 
    AIAssistantStore: AIAssistantServiceStore({ screenCode }),
    
});

export default RMDRAIAssistantStore;
