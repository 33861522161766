/* Common */
import SiteSettingStore from "cms/reducers/common/SiteSettingStore";
import CMSStore from "cms/reducers/common/CMSStore";

/* Components */
import HeaderStore from "cms/reducers/components/HeaderStore";
import FooterStore from "cms/reducers/components/FooterStore";
import SliderStore from "cms/reducers/components/SliderStore";
import MainPageItemStore from "cms/reducers/components/MainPageItemStore";
import MainPagePopupStore from "cms/reducers/components/MainPagePopupStore";
import LatestContents from "cms/reducers/components/LatestContents";
import ShowroomLayoutStore from "cms/reducers/components/ShowroomLayoutStore";
import ContactFormStore from "cms/reducers/components/ContactFormStore";
import RMDRAIAssistantStore from "cms/reducers/components/RMDRAIAssistantStore";
import GetQuoteStore from "cms/reducers/components/GetQuoteStore";
// InnerLayouts
import INNR003Store from "cms/reducers/components/InnerLayouts/INNR003Store";

/* Screens */
import ContentStore from "cms/reducers/screens/ContentStore";


const CMSReducers = {

    /* Common */
    CMSStore,
    SiteSettingStore,

    /* Components */
    HeaderStore,
    FooterStore,
    SliderStore,
    MainPageItemStore,
    MainPagePopupStore,
    LatestContents,
    ShowroomLayoutStore,
    ContactFormStore,
    RMDRAIAssistantStore,
    GetQuoteStore,
    // InnerLayouts
    INNR003Store,
    
    /* Screens */
    ContentStore

}

export default CMSReducers;
