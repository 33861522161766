
import FilterIcon from 'components/Icons/filter-icon';
import FilterSidebar from 'components/FilterSidebar';
import ListBox from 'components/ListBox';

import { Drawer } from 'components/Drawer/Drawer';
import motionProps from 'components/Drawer/Motion';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import IClientSiteSettingDetail from 'cms/interfaces/IClientSiteSettingDetail';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import { EViewType } from 'cms/enums/EViewType';
import { EView } from 'cms/enums/EView';


type TOrderBy = {

    siteSettingPayload:IClientSiteSettingDetail;
    totalItemCount:number;
    isLoading:boolean;

}

function OrderBy ( props:TOrderBy ) {

    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ isOpen, setIsOpen ]     = useState<boolean>(false);

    const priceLowHigh = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "priceLowHigh" }
        ],
        type: "find"
    });

    const priceHighLow = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "priceHighLow" }
        ],
        type: "find"
    });

    const orderList = [
        { name: priceLowHigh?.value, value: 'price asc' },
        { name: priceHighLow?.value, value: 'price desc' },
    ];
    const [ selectedItem, setSelectedItem ]   = useState<{name:string|null|undefined, value:string}|null>(orderList[0]);

    const filters = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "filters" }
        ],
        type: "find"
    });

    const products = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingPayload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "products" }
        ],
        type: "find"
    });

    useEffect(
        () => {

            if ( !isEmpty(selectedItem) ) {
                let _defaultOrder = orderList[0];
                let _orderFromUrl = orderList.find((item) => item.value === searchParams.get("o"));
                
                let _order = _orderFromUrl || _defaultOrder;
                searchParams.set("o", _order.value );
                
                if ( !searchParams.has('cp') ) {
                    searchParams.set("cp", "1" );
                }

                setSearchParams(searchParams, { replace: true });
            }
            else {
                if ( searchParams.has('o') ) {
                    searchParams.delete('o');
                    setSearchParams(searchParams, { replace: true });
                }
            }

        }, [ selectedItem ]
    )

    function sidebarOnClose () {

        setIsOpen(false);
    
    }

    
    function onChange ( value:{name:string, value:string} ) {

        setSelectedItem(value);

    }

    return (
        <div className="flex justify-between items-center mb-7">
            <h1 className="text-heading text-2xl font-bold hidden lg:inline-flex pb-1" />
            <button
                className="lg:hidden text-heading text-sm px-4 py-2 font-semibold border border-gray-300 rounded-md flex items-center transition duration-200 ease-in-out focus:outline-none hover:bg-gray-200"
                onClick={(event:any) => { setIsOpen((prevProps) => !prevProps) }}
            >
                <FilterIcon />
                <span className="pl-2.5">{filters?.value}</span>
            </button>
            <div className="flex items-center justify-end">
                <div className="flex-shrink-0 text-body text-xs md:text-sm leading-4 pr-4 md:mr-6 pl-2 hidden lg:block">
                    {props.totalItemCount}{" "}{products?.value}
                </div>
                <ListBox
                    title=''
                    value={selectedItem}
                    options={orderList}
                    selectorKey={"value"}
                    onChange={onChange}
                    disabled={props.isLoading}
                />
            </div>

            <Drawer
                placement={'left'}
                open={isOpen}
                onClose={(event:any) => { setIsOpen(false) }}
                // contentWrapperStyle={{ left: 0 }}
                {...motionProps}
            >
                <FilterSidebar onClose={sidebarOnClose} totalItemCount={props.totalItemCount} siteSettingPayload={props.siteSettingPayload} />
            </Drawer>
        </div>
    );
}

OrderBy.defaultProps = {

    isLoading: false,

}

export default OrderBy;
