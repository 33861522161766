import { combineReducers } from 'redux';
import CoreReduxStore from 'core/reducers/CoreReduxStore';
// service
import AIAssistantService from 'cms/services/AIAssistantService'
// interface
import IServiceStore from 'core/interfaces/IServiceStore';
import EReduxStoreInitialState from 'core/enums/EReduxStoreInitialState';


function AIAssistantServiceStore({ screenCode }:IServiceStore ) {
    const reduxStorages = new CoreReduxStore({ screenCode: screenCode, serviceCode: AIAssistantService.code });

    return combineReducers({
        
        createThread: reduxStorages.BASE({ 
            storeKey: "armador",
            protocolKey: "CT",
            initialState: EReduxStoreInitialState.DEFAULT
        })["armador"],

        run: reduxStorages.BASE({ 
            storeKey: "armador",
            protocolKey: "R",
            initialState: EReduxStoreInitialState.DEFAULT
        })["armador"],
    
    });
}

export default AIAssistantServiceStore;
