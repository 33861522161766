// components
import BannerCard from "components/BannerCard";
// types
import { TSLDRLayout } from "../type";
// enums
import { EResourceType } from "cms/enums/EResourceType";


function SLDR_001 ( props: TSLDRLayout<{}> ) {
    console.log("SLDR_001: ", props);

    return (
        <BannerCard
            payload={props.payload}
            variant={"default"}
            resourceType={EResourceType.PATTERN}
            effectActive={true}
            className="col-span-full sm:col-span-5"
            navigationURL={props.navigationURL}
            onClick={props.onClick}
        />
    );
}


export default SLDR_001;
