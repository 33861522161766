import ShopFilters from './Filters/ShopFilters';
import Scrollbar from 'components/Scrollbar';
import { IoArrowBack } from 'react-icons/io5';
// interfaces
import IClientSiteSettingDetail from 'cms/interfaces/IClientSiteSettingDetail';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
// utilities
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';
// enums
import { EViewType } from 'cms/enums/EViewType';
import { EView } from 'cms/enums/EView';


type TFilterSidebar = {

    siteSettingPayload:IClientSiteSettingDetail;

    totalItemCount: number;
    onClose: () => void;

}

function FilterSidebar(props: TFilterSidebar) {

    const filters = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.siteSettingPayload.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "filters" }
        ],
        type: "find"
    });

    return (
        <div className="flex flex-col justify-between w-full h-full">
            <div className="w-full border-b border-gray-100 flex justify-between items-center relative pr-5 md:pr-7 flex-shrink-0 py-0.5">
                <button
                    className="flex text-2xl items-center justify-center text-gray-500 px-4 md:px-5 py-6 lg:py-8 focus:outline-none transition-opacity hover:opacity-60"
                    onClick={() => { props.onClose() }}
                    aria-label="close"
                >
                    <IoArrowBack className="text-black" />
                </button>
                <h2 className="font-bold text-xl md:text-2xl m-0 text-heading w-full text-center pr-6">
                    {filters?.value}
                </h2>
            </div>

            <Scrollbar className="menu-scrollbar flex-grow mb-auto">
                <div className="flex flex-col py-7 px-5 md:px-7 text-heading">
                    <ShopFilters siteSettingPayload={props.siteSettingPayload} />
                </div>
            </Scrollbar>

            <div className="text-sm md:text-base leading-4 flex items-center justify-center px-7 flex-shrink-0 h-14 bg-heading text-white">
                {props.totalItemCount} products
            </div>
        </div>
    );
};

export default FilterSidebar;
