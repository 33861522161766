import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
// actions
import CoreAction from "core/actions/common/CoreAction";
import AIAssistantServiceAction from "cms/actions/components/RMDRAIAssistant/AIAssistantServiceAction";
// styles
import 'react-tabs/style/react-tabs.css';
// interfaces
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import SEO from "components/SEO";
import Divider from "components/Divider";


function mapStateToProps ( state: any ) {
    console.log("Assistant.mapStateToProps: ", state);

    const siteSettingDetailPayload:IClientSiteSettingDetail|null   = state.SiteSettingStore.details?.payload;
    
    const threadId:string = state.RMDRAIAssistantStore.AIAssistantStore.createThread?.payload;
    const run:string = state.RMDRAIAssistantStore.AIAssistantStore.run?.payload;
    const isLoading = state.RMDRAIAssistantStore.AIAssistantStore.run?.isLoading;

    return {
        
        siteSettingDetailPayload,

        threadId,
        run,

        isLoading

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("Assistant.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);
    const aiAssistantServiceAction = new AIAssistantServiceAction(dispatch);

    return {

        _createThread: () => {
            aiAssistantServiceAction.createThread({});
        },

        _run: ( threadId:string, message:string ) => {
            aiAssistantServiceAction.run({ servicePayload: { threadId, message } });
        },

        _runClean: () => {
            aiAssistantServiceAction.runClean();
        },

        // others
        _hideLoader: () => {
            coreAction.hideLoader();
        },

        _showLoader: () => {
            coreAction.showLoader();
        },

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TAssistant<T> = {

} & T;


/* component */
function AssistantMessage (props:{ type:string, message:string }) {

    if ( props.type === "assistant" ) {
        return (
            <div className="flex items-end">
                <ReactMarkdown
                    className="p-4 bg-slate-100 mx-3 my-1 rounded-2xl rounded-tl-none text-body max-w-3/4 md:max-w-3/5 rmdrMarkdown"
                    remarkPlugins={[remarkGfm]}
                >
                    {props.message}
                </ReactMarkdown>
            </div>
        )
    }

}

function AssistantMessageloading () {

    return (
        <div className="flex items-end">
            <div className="p-4 bg-slate-100 mx-3 my-1 rounded-2xl rounded-tl-none text-body max-w-3/4 md:max-w-3/5 rmdrMarkdown">
                <div className='flex space-x-2 justify-center items-center mt-3'>
                    <span className='sr-only'>Loading...</span>
                    <div className='h-2 w-2 bg-floorsAndExtras-600 rounded-full animate-bounce-1 duration-300 ![animation-delay:-0.3s]'></div>
                    <div className='h-2 w-2 bg-floorsAndExtras-600 rounded-full animate-bounce-1 duration-150 ![animation-delay:-0.15s]'></div>
                    <div className='h-2 w-2 bg-floorsAndExtras-600 rounded-full animate-bounce-1'></div>
                </div>
            </div>
        </div>
    )

}

function UserMessage (props:{ type:string, message:string }){
    
    if ( props.type === "user" ) {
        return (
            <div className="flex justify-end">
                <div className="p-4 bg-slate-200 m-1 rounded-2xl rounded-br-none max-w-3/4 md:max-w-3/5 text-body">
                    {props.message}
                </div>
            </div>
        )
    }

}

function Assistant ( props:TAssistant<PropsFromRedux> ) {
    console.log("Assistant.rendered: ", props);
    
    const location = useLocation();

    const [ messages, setMessages ] = useState([
        { type: "assistant", message: "Hi! I am Floors And Extras Sales Assistant. How can i help you today!" },
    ]);

    const [ progress, setProgress ] = useState({
        thread: false,
        message: false
    })

    const [ disabled, setDisabled ] = useState(true);
    const [ seoDisabled, setSeoDisabled ] = useState<boolean|null>(null);

    const messageRef = useRef<any>();

    useEffect(
        () => {
            props._hideLoader();
            props._createThread();

            if ( seoDisabled === null ) {
                const _seoDisabled = location.state?.seoDisabled === null || location.state?.seoDisabled === undefined ? false : true;
                setSeoDisabled(_seoDisabled);
            }
        }, []
    )

    useEffect(
        () => {
            if ( !isEmpty(props.threadId) && !progress.thread ) {
                setProgress((prevState) => ({ ...prevState, thread: true, message: false }));
                setDisabled(false);
            }
        }, [ props.threadId ]
    )

    useEffect(
        () => {
            if ( messages.length % 2 === 0 && !progress.message && !isEmpty(props.threadId) && progress.thread ) {
                const userMessage = messages[messages.length-1];
                setProgress((prevState) => ({ ...prevState, message: true }));
                props._run(props.threadId, userMessage.message);
            }
            setTimeout(() => { scrollToLastMessage(); }, 100);
        }, [ messages ]
    )

    useEffect(
        () => {
            if ( !isEmpty(props.run) && progress.message ) {
                setMessages((prevState) => ([ ...prevState, { type: "assistant", message: props.run } ]));
                props._runClean();
                setProgress((prevState) => ({ ...prevState, message: false }));
                setDisabled(false);
            }
        }, [ props.run ]
    )

    function scrollToLastMessage() {
        var bottomElement = messageRef.current.lastElementChild;
        bottomElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    function onSubmit ( event:React.SyntheticEvent ) { 
        
        event.preventDefault();

        if ( !disabled ) {
            setDisabled(true);

            const target = event.target as typeof event.target & {
                userMessage: { value: string },
            };
        
            const payload:any = {
                userMessage: target.userMessage.value,
            }

            setMessages((prevState) => (
                [
                    ...prevState, 
                    { type: "user", message: payload.userMessage }

                ]
            ));

            setTimeout(() => { target.userMessage.value = "" }, 100);
        }
        
    }

    return (
        <>
            {
                seoDisabled === false && ( <SEO siteSettingPayload={props.siteSettingDetailPayload} type="showroom-page" /> )
            }
            
            <Divider className="mb-0" />
            
            <div className="relative md:absolute w-full h-full flex flex-col max-w-[1920px] mx-auto md:top-0 md:left-0 md:bottom-0 md:right-0">
            
                <div ref={messageRef} className="rmdr-scroll-1 w-full min-h-96 max-h-96 md:max-h-none flex-grow my-2 p-2 overflow-y-scroll">
                    {
                        messages.map((item) => (<><UserMessage {...item} /><AssistantMessage {...item} /></>))
                    }
                    {
                        props.isLoading && <AssistantMessageloading />
                    }
                </div>
                    
                <form className="p-3" onSubmit={onSubmit}>
                    <div className="flex items-center">
                        <div className="search-chat flex flex-grow p-2">
                            <input name="userMessage" className="input text-gray-700 dark:text-gray-200 text-sm p-5 focus:outline-none bg-gray-100 dark:bg-gray-800 flex-grow rounded-lg" type="text" placeholder="Type your message ..." disabled={disabled} />
                        </div>
                    </div>
                </form>
                
            </div>
        </>
        
    )
}

const Component = React.memo(Assistant, ( prevProps:TAssistant<PropsFromRedux>, nextProps:TAssistant<PropsFromRedux> ) => {
    console.log("Assistant.memo", { prevProps, nextProps });
    return ![
        prevProps.threadId !== nextProps.threadId,
        prevProps.run !== nextProps.run,
        prevProps.isLoading !== nextProps.isLoading
    ].includes(true);
});

export default connector(Component);
