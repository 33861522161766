import React, { useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from "react-redux"
import Lodash, { isEmpty } from "lodash";
import { Dispatch } from '@reduxjs/toolkit';

import cn from "classnames";
import SearchResultLoader from "./SearchResultLoader";
import SearchBox from "./SearchBox";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import Scrollbar from "./Scrollbar";
import SearchProduct from "./SearchProduct";
import CMSAction from 'cms/actions/common/CMSAction';
import SearchIcon from './Icons/search-icon';
import { useNavigate, useSearchParams } from 'react-router-dom';


function mapStateToProps ( state: any ) {
    console.log("Search.mapStateToProps: ", state);

    const mobileSearchPayload:boolean   = state.CMSStore.mobileSearch.isLoading;
    const isLoading:boolean             = false;
    const data: any[]                   = [];

    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    return {

        mobileSearchPayload,
        isLoading,
        data,

        webApplicationLanguageISOCode,

    }

}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("Search.mapDispatchToProps");

    const cmsAction = new CMSAction(dispatch);

    return {

        _closeMobileSearch: () => {
            cmsAction.closeMobileSearch();
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TSearch<T> = {

} & T;


/* component */
function SearchLoader () {

    return (
        <></>
    )

}

function Search( props:TSearch<PropsFromRedux> ) {

    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();

    const stringSearchRef = React.createRef<HTMLInputElement>();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(
        () => {

            if ( stringSearchRef?.current && isEmpty(stringSearchRef.current.value) ) {
                const searchString = searchParams.get("q");
                if ( searchString && !isEmpty(searchString) )
                stringSearchRef.current.value = searchString;
            }

        }, [ searchParams ]
    )

	useEffect(
        () => {
            
            if (ref.current) {
                if (props.mobileSearchPayload) {
                    disableBodyScroll(ref.current);
                } else {
                    enableBodyScroll(ref.current);
                }
            }

            return () => {
                clearAllBodyScrollLocks();
            };

        }, [props.mobileSearchPayload]
    );

    function onSubmit(event:React.SyntheticEvent) {        
        event.preventDefault();

        const target = event.target as typeof event.target & {
            queryString: { value: string };
        };
        const queryString = target.queryString.value;

        navigate({
            pathname: `/${props.webApplicationLanguageISOCode}/shop`,
            search: `q=${queryString}`
        });

        props._closeMobileSearch();
    }

	return (
		<div ref={ref}>
			<div
				className={
                    cn("overlay", {
                        open: props.mobileSearchPayload,
                    })
                }
				role="button"
				onClick={
                    () => { 
                        props._closeMobileSearch() 
                    }
                }
			/>
			<div
				className={
                    cn(
                        "drawer-search relative hidden top-0 z-30 opacity-0 invisible transition duration-300 ease-in-out left-1/2 px-4 w-full md:w-[730px] lg:w-[930px]",
                        {
                            open: props.mobileSearchPayload,
                        }
				    )
                }
			>
				<div className="w-full flex flex-col justify-center">
					<div className="flex-shrink-0 mt-3.5 lg:mt-4 w-full">
						<div className="flex flex-col mx-auto mb-1.5 w-full ">
							<SearchBox
								name="queryString"
								ref={(input:any) => input && input.focus()}
                                onSubmit={onSubmit}
							/>
						</div>
						{/* {
                            searchText && 
                                (
                                    <div className="bg-white flex flex-col rounded-md overflow-hidden h-full max-h-64vh lg:max-h-[550px]">
                                        <Scrollbar className="os-host-flexbox">
                                            <div className="h-full">
                                                {
                                                    props.isLoading ? 
                                                        (
                                                            <div className="p-5 border-b border-gray-300 border-opacity-30 last:border-b-0">
                                                                {
                                                                    Array.from({ length: 5 }).map((_, idx) => (
                                                                        <SearchResultLoader
                                                                            key={idx}
                                                                            uniqueKey={`top-search-${idx}`}
                                                                        />
                                                                    ))
                                                                }
                                                            </div>
                                                        ) : (
                                                            props.data?.map((item:any, index: number) => (
                                                                <div
                                                                    className="p-5 border-b border-gray-150 relative last:border-b-0"
                                                                    onClick={() => { props._closeMobileSearch() }}
                                                                >
                                                                    <SearchProduct item={item} key={index} />
                                                                </div>
                                                            ))
                                                        )
                                                }
                                            </div>
                                        </Scrollbar>
                                    </div>
                                )
                        } */}
					</div>
				</div>
			</div>
		</div>
	);
}

const Component = React.memo(Search, (prevProps:TSearch<PropsFromRedux>, nextProps:TSearch<PropsFromRedux>) => {
    console.log("Search.memo", { prevProps, nextProps });
    
    return !( 
        prevProps.mobileSearchPayload !== nextProps.mobileSearchPayload
    )
});

export default connector(Component);
