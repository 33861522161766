import Axios, { AxiosRequestConfig } from 'axios';
// core
import CoreDefault from "core/Defaults";
// interfaces
import IAIFetchExtension from 'core/interfaces/IAIFetchExtension';

// constants
declare module "axios" {
  export interface AxiosRequestConfig {
    responseEncoding?: string;
  }
}

async function AIFetchExtension ( params: IAIFetchExtension ) : Promise<any> {
    console.log("FetchExtension.params: ", params);

    const axiosConfig: AxiosRequestConfig = {
        baseURL: CoreDefault.ai,
        url: params.path,
        method: params.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        responseType: 'json',
        responseEncoding: 'utf8',
        validateStatus: function (status: number) {
            return CoreDefault.acceptableHttpStatus.indexOf(status) > -1;
        }
    }

    // body
    if ( params.body ) {
        axiosConfig.data = JSON.stringify(params.body);
    }

    return Axios(axiosConfig);
}

export default AIFetchExtension;