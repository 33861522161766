import React, { useEffect } from 'react'
import { connect, ConnectedProps } from "react-redux"
import { isEmpty } from "lodash";
import { Dispatch } from '@reduxjs/toolkit';
import { useNavigate, useSearchParams } from 'react-router-dom';
// components
import Logo from 'components/Logo';
import SearchIcon from 'components/Icons/search-icon';
import LanguageSwitcher from 'components/LanguageSwitcher';
import CategoryMenu from './components/CategoryMenu';
import HeaderMenu from './components/HeaderMenu';
import SocialMedia01 from 'components/SocialMedia/components/SocialMedia01';
import PhoneNumberBox from './components/PhoneNumberBox';
// actions
import CoreAction from "core/actions/common/CoreAction";
import CMSAction from 'cms/actions/common/CMSAction';
// interfaces
import IClientLanguage from 'core/interfaces/IClientLanguage';
import IClientSiteSettingDetail from 'cms/interfaces/IClientSiteSettingDetail';
import RightMenu from './components/RightMenu';


function mapStateToProps ( state: any ) {
    console.log("Header.mapStateToProps: ", state);

    const languageStorePayload:IClientLanguage[]    = state.LanguageStore.list?.payload;

    const webApplicationLanguage:IClientLanguage   = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string        = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string     = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail         = state.SiteSettingStore.details?.payload;

    return {

        languageStorePayload,

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
        
    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("Header.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);
    const cmsAction = new CMSAction(dispatch);
    

    return {

        // 
        _openMobileMenu: () => {
            cmsAction.openMobileMenu();
        },

        _closeMobileMenu: () => {
            cmsAction.closeMobileMenu();
        },

        // other
        _hideLoader: () => { 
            coreAction.hideLoader(); 
        },

        _showLoader: () => { 
            coreAction.showLoader(); 
        }

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>
type DivElementRef = React.MutableRefObject<HTMLDivElement>;
type THeader<T> = {
    
    site_header?: {
        categoryMenu: {},
        menu: {}
    };

} & T;


/* component */
function HeaderLoader () {

    return (
        <></>
    )

}

function Header ( props:THeader<PropsFromRedux> ) {
    console.log("Header.rendered:", props);

    const [ searchParams ] = useSearchParams();
    
    const navigate = useNavigate();
    const stringSearchRef = React.createRef<HTMLInputElement>();

    useEffect(
        () => {

            if ( stringSearchRef?.current && isEmpty(stringSearchRef.current.value) ) {
                const searchString = searchParams.get("q");
                if ( searchString && !isEmpty(searchString) )
                stringSearchRef.current.value = searchString;
            }

        }, [ searchParams ]
    )

    function search(event:React.SyntheticEvent) {        
        event.preventDefault();

        const target = event.target as typeof event.target & {
            queryString: { value: string };
        };
        const queryString = target.queryString.value;

        navigate({
            pathname: `/${props.webApplicationLanguageISOCode}/shop`,
            search: `q=${queryString}`
        });
    }

    function handleMobileMenu() {

        props._openMobileMenu();

    }

    
    return (
        <header
            id="siteHeader"
            className="relative z-20 w-full h-16 sm:h-20 lg:h-36 xl:h-40 headerThree"
        >
            <div className="fixed z-20 w-full h-16 px-4 text-gray-700 transition duration-200 ease-in-out bg-white innerSticky body-font sm:h-20 lg:h-36 xl:h-40 pl-4 md:pl-0 lg:pl-6 pr-4 lg:pr-6 md:px-8 3xl:px-16">
                <div className="flex items-center justify-center mx-auto max-w-[1920px] h-full lg:h-20 xl:h-24 w-full relative before:absolute before:w-screen before:h-px before:bg-[#F1F1F1] before:bottom-0">
                    <button
                        aria-label="Menu"
                        className="flex-col items-center justify-center flex-shrink-0 hidden h-full px-5 outline-none menuBtn md:flex lg:hidden 2xl:px-7 focus:outline-none"
                        onClick={handleMobileMenu}
                    >
                        <span className="menuIcon">
                            <span className="bar" />
                            <span className="bar" />
                            <span className="bar" />
                        </span>
                    </button>
                    <div className="flex items-center 2xl:mr-12 3xl:mr-20">
                        <Logo siteSettingPayload={props.siteSettingDetailPayload} webApplicationLanguageISOCode={props.webApplicationLanguageISOCode} />

                        {/* <div className="hidden transition-all duration-100 ease-in-out lg:flex ml-7 xl:ml-9 pr-2 headerTopMenu">
                            {site_header.pagesMenu?.map((item: any) => (
                                <Link
                                    href={item.path}
                                    className="relative flex items-center px-3 lg:px-2.5 py-0 text-sm font-normal xl:text-base text-heading xl:px-6 hover:text-black"
                                    key={`pages-menu-${item.id}`}
                                >
                                    {t(`menu:${item.label}`)}
                                    {item.icon && (
                                        <span className="ml-1.5 xl:ml-2">
                                            {item.icon}
                                        </span>
                                    )}
                                </Link>
                            ))}
                        </div> */}
                    </div>

                    <div className="relative hidden w-full mr-auto lg:block">
                        <form
                            className="relative w-full overflow-hidden rounded-md bg-borderBottom"
                            noValidate
                            role="search"
                            onSubmit={search}
                        >
                            <label htmlFor="search" className="flex items-center">
                                <span className="absolute top-0 left-0 flex items-center justify-center flex-shrink-0 w-12 h-full cursor-pointer md:w-14 focus:outline-none">
                                    <SearchIcon
                                        color="text-heading"
                                        className="w-[18px] h-[18px]"
                                    />
                                </span>
                                <input
                                    id="search"
                                    ref={stringSearchRef}
                                    name="queryString"
                                    type="text"
                                    className="w-full text-sm placeholder-gray-400 bg-transparent rounded-md outline-none focus:border-2 focus:border-gray-600 pr-4 pl-14 h-14 text-heading lg:text-base"
                                    placeholder={'Search Anything...'}
                                    aria-label="search"
                                    autoComplete="off"
                                />
                            </label>
                        </form>
                    </div>

                    <div className="flex flex-shrink-0 transition-all duration-200 ease-in-out transform ml-auto mr-0 sm:mr-3 lg:mr-5 xl:mr-8 2xl:mr-10 languageSwitcher lg:hidden">
                        <LanguageSwitcher languagePayload={props.languageStorePayload} webApplicationLanguageISOCode={props.webApplicationLanguageISOCode} showLoader={props._showLoader} />
                    </div>

                    <div className="hidden lg:flex items-center justify-end flex-shrink-0 2xl:ml-12 3xl:ml-20">
                        <div className="flex items-center transition-all wishlistShopping gap-x-5 pl-3">
                            <SocialMedia01 
                                payload={props.siteSettingDetailPayload.languageVersions}  
                                className='text-xs lg:text-sm text-body flex items-center gap-x-3 lg:gap-x-3.5'
                            />

                            <div className="border-l border-gray-300 border-solid w-1 h-8" />

                            <PhoneNumberBox payload={props.siteSettingDetailPayload.languageVersions}  />

                            {/* <div className="flex md:gap-x-4 align-center ">
                <WishButton />
                <span className="hidden text-sm font-semibold transition-all duration-100 ease-in-out cursor-pointer lg:font-normal lg:block xl:text-base text-heading">
                  {t('menu:menu-wishlist')}
                </span>
              </div>
              <div className="hidden lg:flex md:gap-x-4 align-center">
                <CartButton />
                <span className="hidden text-sm font-semibold transition-all duration-100 ease-in-out cursor-pointer lg:font-normal lg:block xl:text-base text-heading">
                  {t('menu:menu-shopping')}
                </span>
              </div> */}
                        </div>
                    </div>
                </div>

                <div className="items-center hidden lg:flex lg:h-16 headerBottom mx-auto max-w-[1920px]">
                    <div className="flex items-center">
                        <CategoryMenu />
                        <HeaderMenu />
                    </div>

                    <div className="flex items-center flex-shrink-0 ml-auto gap-x-7">
                        <LanguageSwitcher languagePayload={props.languageStorePayload} webApplicationLanguageISOCode={props.webApplicationLanguageISOCode} showLoader={props._showLoader} />
                        <RightMenu />
                    </div>
                </div>
            </div>
        </header>
    );
    
}

const Component = React.memo(Header, ( prevProps:THeader<PropsFromRedux>, nextProps:THeader<PropsFromRedux> ) => {
    console.log("Header.memo", { prevProps, nextProps });

    return !( 
        prevProps.webApplicationLanguage !== nextProps.webApplicationLanguage
        || prevProps.siteSettingDetailPayload !== nextProps.siteSettingDetailPayload
    );
});

export default connector(Component);
