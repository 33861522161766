// action 
import ActionCore from "core/actions/ActionCore";
// service
import AIAssistantService from "cms/services/AIAssistantService";
import { IActionAIAssistantService } from "cms/interfaces/IActionAIAssistantService";
import IActionRunParams from "core/interfaces/IActionRunParams";
import IServiceResponse from "core/interfaces/IServiceResponse";
import IActionCallbackArgs from "core/interfaces/IActionCallbackArgs";
import EActionStatus from "core/enums/EActionStatus";


class AIAssistantServiceAction extends ActionCore {

    constructor( dispatch: any, screenCode:string ) {
        super(dispatch, AIAssistantService, screenCode);
    }

    async createThread ( params: IActionAIAssistantService ) {

        this.fetching("CT");
        
        const serviceParams: IActionRunParams = {
            args: {
                ...params.args, 
                actionType: "CT",
            },
            servicePayload: params.servicePayload
        }
        
        this.executeService( this.service.createThread.bind(this.service), serviceParams );

    }

    createThreadClean () {

        this.clean("CT");

    }

    async run ( params: IActionAIAssistantService ) {

        this.fetching("R");

        const serviceParams: IActionRunParams = {
            args: {
                ...params.args, 
                actionType: "R",
            },
            servicePayload: params.servicePayload
        }
        
        this.executeService( this.service.run.bind(this.service), serviceParams );

    }

    runClean () {

        this.clean("R");

    }

    callback200( response: IServiceResponse, args?: IActionCallbackArgs ) {

        if ( args?.actionType === "CT" || args?.actionType === "R" ) {
            this.dispatch({ 
                type: `${this.screenCode}${this.serviceCode}${args.actionType}${EActionStatus.SUCCESS}`, 
                payload: response.value,
                count: response?.["@odata.count"]
            });
        }

    }

}

export default AIAssistantServiceAction;
