import React, { Suspense } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// components
import SEO from "components/SEO";
// import CookieModal from "cms/components/CookieModal";
// actions
import CoreAction from "core/actions/common/CoreAction";
// styles
import 'react-tabs/style/react-tabs.css';
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
// lazy element
const SliderLazy = React.lazy(() => import("components/Slider"));
const MainPageItemsLazy = React.lazy(() => import("components/MainPageItems"));
const MainPagePopupLazy = React.lazy(() => import("components/MainPagePopup"));


function mapStateToProps ( state: any ) {
    console.log("Home.mapStateToProps: ", state);

    const siteSettingDetailPayload:IClientSiteSettingDetail|null   = state.SiteSettingStore.details?.payload;
    
    return {
        
        siteSettingDetailPayload

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("Home.mapDispatchToProps");

    const coreAction = new CoreAction(dispatch);

    return {

        // others
        _hideLoader: () => {
            coreAction.hideLoader();
        },

        _showLoader: () => {
            coreAction.showLoader();
        },

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type THome<T> = {

} & T;


/* component */
function WebApplicationStarterLoader () {

    return (
        <></>
    )

}

function Home ( props:THome<PropsFromRedux> ) {
    console.log("Home.rendered: ", props);
    
    return (
        <>
            <SEO siteSettingPayload={props.siteSettingDetailPayload} type="home-page" />
            <main>
                <Suspense fallback={<div></div>}>
                    <SliderLazy />
                </Suspense>

                <Suspense fallback={<div></div>}>
                    <MainPageItemsLazy />
                </Suspense>

                <Suspense fallback={<div></div>}>
                    <MainPagePopupLazy />
                </Suspense>
            </main>
        </>
    )
}



const Component = React.memo(Home, ( prevProps:THome<PropsFromRedux>, nextProps:THome<PropsFromRedux> ) => {
    console.log("Home.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
