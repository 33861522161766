import cn from 'classnames';
// components
import RMDRLink from './RMDRLink';
import RMDRImage from './RMDRImage';
// interfaces
import IClientMainPageItemList from 'cms/interfaces/IClientMainPageItemList';
import IClientSliderItemList from 'cms/interfaces/IClientSliderItemList';
// enums
import { EView } from 'cms/enums/EView';
import { EViewType } from 'cms/enums/EViewType';
import { EContentType } from 'cms/enums/EContentType';
import { EResourceType } from 'cms/enums/EResourceType';
import ILanguageVersionList from 'cms/interfaces/ILanguageVersionList';
import LanguageVersionSelector from 'cms/utilities/LanguageVersionSelector';

type TBannerBard = {

    payload?: IClientMainPageItemList|IClientSliderItemList|null;
    navigationURL?: string|null;

    className?: string;
    classNameInner?: string;

    variant?: 'rounded' | 'default';
    resourceType?:EResourceType;
    
    effectActive?: boolean;
    disableBorderRadius?: boolean;
    showLoader: boolean;

    onClick?:(event:any) => void;

}

function BannerCardLoader ( props:TBannerBard ) {

    return (
        <div className={cn('h-80 w-full mx-auto', props.className)}>
            <div className={cn('h-full group flex justify-center relative overflow-hidden',props.classNameInner)}>
                <div className={cn('h-full bg-gray-100 object-cover w-full', { 'rounded-md': props.variant === 'rounded' && !props.disableBorderRadius})} />
            </div>
        </div>
    )

}

function BannerCard ( props:TBannerBard ) {

    if ( props.showLoader ) return <BannerCardLoader {...props} />

    const languageVersion = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.payload?.languageVersions,
        filters: [
            { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
        ],
        type: "find"
    });

    const redirectUrl = LanguageVersionSelector<ILanguageVersionList|null>({
        languageVersions: props.payload?.languageVersions,
        filters: [
            { view: EView.META_DATA, viewType: EViewType.TEXT, name: "redirectUrl" }
        ],
        type: "find"
    });

    return (
        <div className={cn('mx-auto', props.className)}>
            <RMDRLink
                title={languageVersion?.name}
                hasNavigationDestination={props.payload?.hasDestinationNavigation}
                navigationURL={props.navigationURL || languageVersion?.contentRouteNavigationURL}
                redirectType={props.payload?.redirectType}
                redirectUrl={redirectUrl?.value}
                isShowAlways
                className={cn('h-full group flex justify-center relative overflow-hidden',props.classNameInner)}
                onClick={props.onClick}
            >
                <RMDRImage
                    resourceType={props.resourceType} 
                    resources={props.payload?.resources} 
                    alt={languageVersion?.name} 
                    title={languageVersion?.name} 
                    className={cn('bg-gray-300 object-cover w-full', {
                        'rounded-md': props.variant === 'rounded' && !props.disableBorderRadius,
                    })}
                />
                {
                    props.effectActive === true && (
                        <div className="absolute top-0 -left-[100%] h-full w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-transparent to-white opacity-40 group-hover:animate-shine" />
                    )
                }
            </RMDRLink>
        </div>
    )

}

BannerCard.defaultProps = {

    variant: 'rounded',
    effectActive: false,
    disableBorderRadius: false,
    showLoader: false,

}

export default BannerCard;
export {
    BannerCardLoader
}
