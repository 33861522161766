import React from "react";
import { connect, ConnectedProps } from "react-redux"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Dispatch } from "@reduxjs/toolkit";
// components
import WebApplicationRedirector from "components/WebApplicationRedirector";
import WebApplicationStarter from "components/WebApplicationStarter";
// layouts
import WebApplicationLayout from "cms/layouts/WebApplicationLayout";
import WebApplicationWrapperLayout from "cms/layouts/WebApplicationWrapperLayout";
// screens
import Home from "screens/Home";
import MarketPlace from "screens/MarketPlace";
import Content from "screens/Content";
import AIAssistant from "screens/AIAssistant";
import Error404 from "components/Error404";
import Preloader from "components/Preloader";


function mapStateToProps ( state: any ) {
    console.log("AppRoutes.mapStateToProps: ", state);

    return {

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("AppRoutes.mapDispatchToProps");

    return {

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TAppRoutes<T> = {

} & T;


/* component */
function AppRoutes ( props:TAppRoutes<PropsFromRedux> ) {
    console.log("AppRoutes.rendered: ", props);

    return (
        <BrowserRouter>
            <Preloader />

            <Routes>

                <Route path="/" element={<WebApplicationStarter />} >
                    <Route element={<WebApplicationWrapperLayout />}>

                        {/* redirect: language */}
                        <Route index element={<WebApplicationRedirector />} />
                        <Route path="index" element={<WebApplicationRedirector />} />
                        <Route path="home" element={<WebApplicationRedirector />} />

                        {/* redirect: pages */}
                        <Route path=":language" element={<WebApplicationLayout />}>
                            <Route index element={<Home />} />
                            <Route path="shop" element={<MarketPlace />} />
                            <Route path="ai" element={<AIAssistant />} />
                            <Route path=":contentName/:contentId" element={<Content />} />
                        </Route>

                    </Route>
                </Route>

                {/* redirect: page not fount  */}
                <Route path="*" element={<Error404 />} />
                
            </Routes>
            
            <ToastContainer />
        </BrowserRouter>
    );

};

const Component = React.memo(AppRoutes, ( prevProps:TAppRoutes<PropsFromRedux>, nextProps:TAppRoutes<PropsFromRedux> ) => {
    console.log("AppRoutes.memo", { prevProps, nextProps });
    return true;
});

export default connector(Component);
