import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import cn from "classnames";
// components
import { IoMdClose } from "react-icons/io";


type TRMDRPopup = {

    isOpen?: boolean;

    showButton?: boolean;
    buttonTitle?: string;

    enableClickOutside?: boolean;
    enableCloseButton?: boolean;

    className?: string;
    contentClassName?: string;

    children: React.ReactNode;

}

function RMDRPopup ( props:Readonly<TRMDRPopup> ) {
    console.log("RMDRPopup: ", props);

    const [ isShow, setIsShow ] = useState("hidden");
    const [ popupRef, setPopupRef ] = useState<any>();

    useEffect(() => {
        
        if ( !isEmpty(popupRef) ) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return function cleanup() {
            document.removeEventListener("mousedown", handleClickOutside);
        }

    }, [ popupRef, props.enableClickOutside ])

    useEffect(
        () => {

            if ( props.isOpen === true ) {
                onShow();
            } else if ( props.isOpen === false ) {
                onClose()
            }

        }, [ props.isOpen ]
    )

    function onClose ( event?:any ) {

        if ( props.enableCloseButton ) {
            setIsShow("hidden");
        }

    }

    function onShow ( event?:any ) {

        setIsShow("flex");

    }

    function handleClickOutside (event:any) {

        console.log("enableClickOutside: ", props.enableClickOutside)

        if (popupRef && !popupRef.contains(event.target) && props.enableClickOutside) {
            onClose( event );
        }
        
    }

    return (
        <>
            {
                props.showButton && (
                    <button
                        className="flex items-center justify-center gap-2 px-3.5 xl:px-4 text-m relative before:absolute before:-bottom-2.5 before:h-2.5 before:w-full before:z-10 font-semibold text-white transition-colors rounded-md cursor-pointer h-11 bg-floorsAndExtras-600"    
                        onClick={onShow}
                    >
                        {props.buttonTitle}
                    </button>
                )
            }
            <div 
                id="static-modal" aria-hidden="true" 
                className={cn(
                    "overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-black bg-opacity-60",
                    isShow
                )}
            >
                <div 
                    className={cn(
                        "relative p-4 w-full max-w-2xl max-h-full",
                        props.className
                    )}
                >
                    <button className="absolute right-0 top-0 rounded-full bg-white z-[1] w-8 h-8 inline-flex items-center justify-center" onClick={onClose}>
                        <IoMdClose className="w-5 h-5 text-black" />
                    </button>
                    
                    <div 
                        ref={(payload:any) => {setPopupRef(payload)}} 
                        className={cn(
                            "relative rounded-lg shadow",
                            props.contentClassName
                        )}
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )

};

RMDRPopup.defaultProps = {

    isOpen: undefined,

    showButton: false,
    buttonTitle: "",

    enableClickOutside: true,
    enableCloseButton: true,

    className: "",
    contentClassName: "bg-white p-4"


}

export default RMDRPopup;