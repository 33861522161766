import React, { useEffect, useState } from "react";
import { isEmpty, reject } from "lodash";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
// actions
import ContactUsServiceAction from "cms/actions/components/GetQuote/ContactUsServiceAction";
import MessagePublisherAction from "core/actions/common/MessagePublisherAction";
// interfaces
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientProductDetails from "ecommerce/interfaces/IClientProductDetails";
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
// types
import TValidations from "cms/types/TValidations";
import RMDRPopup from "./RMDRPopup";
import RMDRTextBox from "./RMDRTextBox";
import RMDRButton from "./RMDRButton";
import RMDRTextArea from "./RMDRTextArea";
import LanguageVersionSelector from "cms/utilities/LanguageVersionSelector";
import { EView } from "cms/enums/EView";
import { EViewType } from "cms/enums/EViewType";
import { EContentType } from "cms/enums/EContentType";
import ILanguageVersionList from "cms/interfaces/ILanguageVersionList";
import IBase from "cms/interfaces/IBase";
import IClientProductList from "ecommerce/interfaces/IClientProductList";
import { EContactUsStatus } from "cms/enums/EContactUsStatus";


function mapStateToProps ( state: any ) {
    console.log("GetQuote.mapStateToProps: ", state);

    const webApplicationLanguage:IClientLanguage    = state.CoreStore.language?.payload;
    const webApplicationLanguageCode:string         = webApplicationLanguage.code;
    const webApplicationLanguageISOCode:string      = state.CoreStore.languageCode?.payload;

    const siteSettingDetailPayload:IClientSiteSettingDetail = state.SiteSettingStore.details?.payload;

    const contactUsPostPayload:IBase                            = state.GetQuoteStore.ContactUsStore.post.payload;
    const contactUsPostError:TValidations[]|null                = state.GetQuoteStore.ContactUsStore.post.error?.value;
    const contactUsPostIsLoading:boolean                        = state.GetQuoteStore.ContactUsStore.post.isLoading;

    const errors:TValidations[]|null|undefined      = reject( [contactUsPostError], isEmpty )[0] || [];
    const isLoading:boolean                         = [contactUsPostIsLoading].includes(true);

    return {

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        siteSettingDetailPayload,
        contactUsPostPayload,

        errors,
        isLoading

    }
}

function mapDispatchToProps ( dispatch:Dispatch ) {
    console.log("GetQuote.mapDispatchToProps");

    const messagePublisherAction = new MessagePublisherAction(dispatch);
    const contactUsServiceAction = new ContactUsServiceAction(dispatch);

    return {

        _post: ( payload: { nameSurname:string|null, email:string|null, title:string|null, message:string|null }, webApplicationLanguageCode:string ) => {
            contactUsServiceAction.post({ servicePayload: { body: { ...payload, status: EContactUsStatus.NEW }, language: webApplicationLanguageCode } });
        },

        _showMessage: ( message:string ) => { 
            messagePublisherAction.showSuccessMessage(message);
        },

        _showErrorMessage: ( message:string ) => { 
            messagePublisherAction.showErrorMessage(message);
        },

        _clean: () => {
            contactUsServiceAction.postClean();
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>

type TGetQuote<T> = {

    payload:IClientProductDetails|IClientProductList;

} & T;

/* component */
function GetQuote ( props:TGetQuote<PropsFromRedux> ) {
    console.log("GetQuote.rendered: ", props);

    const [ disabled, setDisabled ] = useState(false);
    const [ formEvent, setFormEvent ] = useState<React.SyntheticEvent|null>(null);
    const [ popupStatus, setPopupStatus ] = useState<boolean|undefined>(undefined);

    useEffect(
        () => {

            return function cleanup() {
                props._clean();
            }

        }, []
    )

    useEffect(
        () => {
            if ( !isEmpty(props.contactUsPostPayload?.id) && formEvent ) {

                const target = formEvent.target as typeof formEvent.target & {
                    nameSurname: { value: string },
                    email: { value: string },
                    phoneNumber: { value: string },
                    quantity: { value: number },
                    cityCountry: { value: string },
                    message: { value: string },
                };

                target.nameSurname.value = "";
                target.email.value = "";
                target.phoneNumber.value = "";
                target.quantity.value = 0;
                target.cityCountry.value = "";
                target.message.value = "";

                props._clean();
                setDisabled(false);
                setFormEvent(null);
                setPopupStatus(false);
                setTimeout(() => { setPopupStatus(undefined); }, 100);

                props._showMessage("Message has been sent.");

            }
        }, [ props.contactUsPostPayload ]
    )

    useEffect(
        () => {

            if ( !isEmpty(props.errors) && !props.isLoading ) {
                setDisabled(false);
                setFormEvent(null);
                props._showErrorMessage("An error occurred");
            }

        }, [ props.errors ]
    )

    function onSubmit(event:React.SyntheticEvent) { 
        event.preventDefault();

        setDisabled(true);
        setFormEvent(event);

        const target = event.target as typeof event.target & {
            nameSurname: { value: string },
            email: { value: string },
            phoneNumber: { value: string },
            quantity: { value: number },
            cityCountry: { value: string },
            message: { value: string },
        };

        const productName = LanguageVersionSelector<ILanguageVersionList|null>({
            languageVersions: props.payload.languageVersions,
            filters:[
                { view: EView.OUTTER, viewType: EViewType.NAME_DESCRIPTION, contentType: EContentType.DEFAULT }
            ],
            type: "find"
        })

        const messageList: string[] = [
            `Product Name: ${productName?.name}`,
            `Product URL: ${window.location.href}`,
            `Phone Number: ${target.phoneNumber.value}`,
            `Quantity: ${target.quantity.value}`,
            `City/Country: ${target.cityCountry.value}`,
            `Message:\n ${target.message.value}`,
        ];

        const payload:any = {
            nameSurname: target.nameSurname.value,
            email: target.email.value,
            title: `Get A Best Quote ( ${productName?.name} )`,
            message: messageList.join("\n")
        }

        props._post(payload, props.webApplicationLanguageCode);
    }

    return (
        <RMDRPopup
            isOpen={popupStatus}
            showButton={true}
            contentClassName={"bg-white p-8"}
            buttonTitle="GET A BEST QUOTE"
            enableClickOutside={!disabled}
            enableCloseButton={!disabled}
        >
            <form
                onSubmit={onSubmit}
                className="w-full mx-auto flex flex-col space-y-5 justify-center "
                noValidate
            >
                <div className="relative">
                    <label htmlFor="nameSurname" className="block font-semibold text-sm leading-none mb-3">Your Name (required)</label>
                    <RMDRTextBox 
                        name="nameSurname" 
                        placeholder={"Enter Your Name"} 
                        disabled={disabled} 
                        errors={props.errors}
                        className="py-2 px-4 md:px-5 w-full appearance-none border text-input text-xs lg:text-sm font-body placeholder-body min-h-12 bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12 rounded-md"
                    />
                </div>

                <div className="relative">
                    <label htmlFor="email" className="block font-semibold text-sm leading-none mb-3">Your Email (required)</label>
                    <RMDRTextBox 
                        name="email" 
                        placeholder={"Enter Your e-Mail"} 
                        disabled={disabled} 
                        errors={props.errors}
                        className="py-2 px-4 md:px-5 w-full appearance-none border text-input text-xs lg:text-sm font-body placeholder-body min-h-12 bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12 rounded-md"
                    />
                </div>

                <div className="relative">
                    <label htmlFor="phoneNumber" className="block font-semibold text-sm leading-none mb-3">Your Phone (required)</label>
                    <RMDRTextBox 
                        name="phoneNumber" 
                        placeholder={"Enter Your Phone Number"} 
                        disabled={disabled} 
                        errors={props.errors}
                        className="py-2 px-4 md:px-5 w-full appearance-none border text-input text-xs lg:text-sm font-body placeholder-body min-h-12 bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12 rounded-md"
                    />
                </div>

                <div className="relative">
                    <label htmlFor="quantity" className="block font-semibold text-sm leading-none mb-3">Quantity (required)</label>
                    <RMDRTextBox
                        name="quantity" 
                        type="number"
                        min={0}
                        placeholder={"Enter Quantity"}
                        disabled={disabled} 
                        errors={props.errors}
                        className="py-2 px-4 md:px-5 w-full appearance-none border text-input text-xs lg:text-sm font-body placeholder-body min-h-12 bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12 rounded-md"
                    />
                </div>

                <div className="relative">
                    <label htmlFor="cityCountry" className="block font-semibold text-sm leading-none mb-3">City / Country (required)</label>
                    <RMDRTextBox 
                        name="cityCountry" 
                        placeholder={"Enter City/Country"} 
                        disabled={disabled} 
                        errors={props.errors}
                        className="py-2 px-4 md:px-5 w-full appearance-none border text-input text-xs lg:text-sm font-body placeholder-body min-h-12 bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12 rounded-md"
                    />
                </div>

                <div className="relative mb-4">
                    <label htmlFor="message" className="block font-semibold text-sm leading-none mb-3">Your Message</label>
                    <RMDRTextArea 
                        name="message" 
                        placeholder={"Enter Your Message"}
                        disabled={disabled}
                        rows={5}
                        errors={props.errors}
                        className="px-4 py-3 flex items-center w-full rounded appearance-none text-heading text-sm focus:ring-0 bg-white border border-gray-300 focus:shadow focus:outline-none focus:border-heading placeholder-body"
                    />
                </div>

                <div className="relative">
                    <RMDRButton 
                        type="submit" 
                        value="SEND" 
                        disabledValue="Sending ..." 
                        disabled={disabled} 
                        className="text-[13px] md:text-sm leading-4 inline-flex items-center cursor-pointer font-semibold font-body text-center justify-center border-0 border-transparent placeholder-white focus-visible:outline-none focus:outline-none rounded-md  text-white  hover:text-white bg-floorsAndExtras-700 hover:bg-floorsAndExtras-800 hover:shadow-cart h-11 text-sm lg:text-base w-full"
                    />
                </div>
            </form>
        </RMDRPopup>
    )
    
}

const Component = React.memo(GetQuote, ( prevProps: TGetQuote<PropsFromRedux>, nextProps: TGetQuote<PropsFromRedux> ) => {
    console.log("GetQuote.memo", { prevProps, nextProps });
    return ![
        prevProps.errors !== nextProps.errors,
        prevProps.contactUsPostPayload !== nextProps.contactUsPostPayload
    ].includes(true);
});

export default connector(Component);
