import { combineReducers } from 'redux';

// reducers
import ContactUsServiceStore from "cms/reducers/services/ContactUsServiceStore";


const screenCode = "215";
const ContactFormStore = combineReducers({
 
    ContactUsStore: ContactUsServiceStore({ screenCode }),

});

export default ContactFormStore;
