import { ICreateThread, IRun } from 'cms/interfaces/IAIAssistantService';
import HttpMethodEnum from 'core/enums/EHttpMethod';
import AIFetchExtension from 'core/extensions/AIFetchExtension';
import IAIFetchExtension from "core/interfaces/IAIFetchExtension";
import ServiceBase from "core/services/ServiceBase";


class AIAssistantService extends ServiceBase {

    static code = "208";
    code = "208";

    constructor () {
        super({ sourcePath: "" });
    }

    async createThread( params: ICreateThread ): Promise<any> {

        let ts = Date.now();

		const apiFetchConfig: IAIFetchExtension = {
			
            method: HttpMethodEnum.GET,
			path: `createThread?ts=${ts}`
		}

		return AIFetchExtension(apiFetchConfig);
	
    }

    async run( params: IRun ): Promise<any> {

        let ts = Date.now();

		const apiFetchConfig: IAIFetchExtension = {
			
            method: HttpMethodEnum.POST,
			path: `run/${params.threadId}?ts=${ts}`,
            body: {
                message: params.message
            }
		}

		return AIFetchExtension(apiFetchConfig);
	
    }
    

}

export default AIAssistantService;
