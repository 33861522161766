import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";
import { Dispatch } from "@reduxjs/toolkit";
// components
import SliderLayouts from "components/SliderLayouts";
import { BannerCardLoader } from "components/BannerCard";
//
import IClientSiteSettingDetail from "cms/interfaces/IClientSiteSettingDetail";
import IClientLanguage from "core/interfaces/IClientLanguage";
import IClientCatalogCategoryList from "ecommerce/interfaces/IClientCatalogCategoryList";
// 
import { TSLDRGRPLayout } from "../type";
import { EContentType } from "cms/enums/EContentType";
//
import ContentMetricsServiceAction from "cms/actions/components/Slider/ContentMetricsServiceAction";
import { EContentMetricsType } from "core/enums/EContentMetricsType";


function mapStateToProps ( state: any ) {
    console.log("Slider.mapStateToProps: ", state);

    const siteSettingDetailPayload:IClientSiteSettingDetail     = state.SiteSettingStore.details?.payload;

    const webApplicationLanguage: IClientLanguage               = state.CoreStore.language?.payload;
    const webApplicationLanguageCode: string                    = webApplicationLanguage.code;
    const webApplicationLanguageISOCode: string                 = state.CoreStore.languageCode?.payload;

    const catalogCategoryListPayload: IClientCatalogCategoryList[] = state.ClientCatalogCategoryStore.list.payload;

    return {

        siteSettingDetailPayload,

        webApplicationLanguage,
        webApplicationLanguageCode,
        webApplicationLanguageISOCode,

        catalogCategoryListPayload,

    }
}

function mapDispatchToProps ( dispatch: Dispatch ) {
    console.log("SliderGroup.mapDispatchToProps");

    const contentMetricsServiceAction = new ContentMetricsServiceAction(dispatch);

    return {

        _viewEvent: ( contentId:string, webApplicationLanguageCode:string ) => {
            contentMetricsServiceAction.post({ 
                servicePayload: { 
                    body: {
                        contentId: contentId,
                        metricType: EContentMetricsType.VIEV
                    }, 
                    language: webApplicationLanguageCode 
                } 
            });
        },

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


/* types */
type PropsFromRedux = ConnectedProps<typeof connector>


/* component */
function SLDRGRP001Loader () {

    return (
        <div className="mb-12 md:mb-14 xl:mb-16 px-2.5 grid grid-cols-2 sm:grid-cols-9 gap-2 md:gap-2.5 max-w-[1920px] mx-auto animate-pulse">
            <BannerCardLoader showLoader={true} className="col-span-full sm:col-span-5" />
            <BannerCardLoader showLoader={true} className="col-span-1 sm:col-span-2" />
            <BannerCardLoader showLoader={true} className="col-span-1 sm:col-span-2" />
            <BannerCardLoader showLoader={true} className="col-span-1 sm:col-span-2" />
            <BannerCardLoader showLoader={true} className="col-span-1 sm:col-span-2" />
            <BannerCardLoader showLoader={true} className="col-span-full sm:col-span-5" />
        </div>
    )

}

function SLDRGRP001 ( props: TSLDRGRPLayout<PropsFromRedux> ) {
    console.log("SLDRGRP001.rendered: ", props);

    if ( props.payload && isEmpty(props.payload) ) {
        return <SLDRGRP001Loader />
    }
    // else if (props.sliderItemListIsLoading === false && isEmpty(props.sliderItemListPayload) && isEmpty(props.sliderItemListError)) {
    //     return (
    //         <div className="mb-12 md:mb-14 xl:mb-16 max-w-[1920px] mx-auto">
    //             <NoData
    //                 className="flex flex-col items-center justify-center h-80 bg-gray-100"
    //                 textClassName="text-center text-xl"
    //                 iconClassName="stroke-1 text-8xl"
    //                 siteSettingPayload={props.siteSettingDetailPayload}
    //             />
    //         </div>
    //     )
    // }
    else if (!isEmpty(props.payload)) {

        return (
            <div className="mb-12 md:mb-14 xl:mb-16 px-2.5 grid grid-cols-2 sm:grid-cols-9 gap-2 md:gap-2.5 max-w-[1920px] mx-auto">
                {
                    props.payload?.map((item) => {
                        const layoutName = item.layouts?.[0]?.layoutName;
                        const Layout = SliderLayouts[layoutName]
                        
                        let navigationURL = null;
                        if ( item.contentContentType === EContentType.CATALOG_CATEGORY ) {
                            const hierarchyId = props.catalogCategoryListPayload.find((x) => x.id === item.contentId)?.hierarchyId;
                            navigationURL = `/${props.webApplicationLanguageISOCode}/shop?c=${hierarchyId}`;
                        }

                        if ( Layout ) {
                            return <Layout payload={item} navigationURL={navigationURL} onClick={(event:any) => { props._viewEvent(item.contentId, props.webApplicationLanguageCode) }} />
                        }
                        
                        return null;
                    })
                }
            </div>
        );

    }

    return null;

}

const Component = React.memo(SLDRGRP001, ( prevProps: TSLDRGRPLayout<PropsFromRedux>, nextProps: TSLDRGRPLayout<PropsFromRedux> ) => {
    console.log("SLDRGRP001.memo", { prevProps, nextProps });

    return !(
        prevProps.webApplicationLanguageISOCode !== nextProps.webApplicationLanguageISOCode
        || prevProps.payload !== nextProps.payload
    )
});

export default connector(Component);
